<form [formGroup]="profileForm">
    <div class="cc6">
        <mat-form-field appearance="fill" color="primary">
            <mat-label>Vorname</mat-label>
            <input matInput placeholder="Vorname" name="firstname" formControlName="firstname" required>
            <mat-error>Vorname (Pflichtfeld)</mat-error>
        </mat-form-field>
    </div>
    <div class="cc6">
        <mat-form-field appearance="fill" color="primary">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" name="lastname" formControlName="lastname" required>
            <mat-error>Name (Pflichtfeld)</mat-error>
        </mat-form-field>
    </div>
    <ng-container *ngIf="ismail() else tel">
        <div class="cc6">
            <mat-form-field appearance="fill" color="primary">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" name="email" formControlName="email" required>
                <mat-error>Email (Pflichtfeld)</mat-error>
            </mat-form-field>
        </div>
        <div class="cc6">
            <mat-form-field appearance="fill" color="primary">
                <mat-label>Telefonnummer</mat-label>
                <input matInput placeholder="Telefonnummer" name="tel" formControlName="tel">
            </mat-form-field>
        </div>
    </ng-container>
    <ng-template #tel>
        <div class="cc6">
            <mat-form-field appearance="fill" color="primary">
                <mat-label>Telefonnummer</mat-label>
                <input matInput placeholder="Telefonnummer" name="tel" formControlName="tel" required>
                <mat-error>Telefonnummer (Pflichtfeld)</mat-error>
            </mat-form-field>
        </div>
        <div class="cc6">
            <mat-form-field appearance="fill" color="primary">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" name="email" formControlName="email">
            </mat-form-field>
        </div>
    </ng-template>
    <div class="cc6">
        <mat-form-field appearance="fill" color="primary">
            <mat-label>Ihre Nachricht</mat-label>
            <textarea matInput name="message" placeholder="Ihre nachricht" formControlName="message"></textarea>
        </mat-form-field>
    </div>
</form>
<div class="cc6">
    <button class="fbtn-s" (click)="close()">Zurück</button>
    <button class="fbtn-se" (click)="send()">Senden</button>
</div>