import { Component, OnInit, input, output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface Fromular {
    firstname: string;
    lastname: string;
    tel: string;
    email: string;
    message: string;
}

@Component({
    selector: 'clever-formular',
    templateUrl: './formular.component.html',
    styleUrls: ['./formular.component.scss'],
    standalone: false
})
export class FormularComponent implements OnInit {
    readonly ismail = input<boolean>(false);
    readonly sendFormular = output<any>();
    readonly closeFormular = output<any>();
    profileForm: FormGroup;
    constructor(
        protected fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.initFormular();
    }

    initFormular(): void {
        if (this.ismail()) {
            this.profileForm = this.fb.group({
                firstname: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
                lastname: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
                tel: new FormControl('', [Validators.minLength(1), Validators.maxLength(20)]),
                email: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.email]),
                message: new FormControl('', [Validators.minLength(1), Validators.maxLength(1000)]),
            });
        } else {
            this.profileForm = this.fb.group({
                firstname: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
                lastname: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
                tel: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
                email: new FormControl('', [Validators.minLength(1), Validators.maxLength(100), Validators.email]),
                message: new FormControl('', [Validators.minLength(1), Validators.maxLength(10000)]),
            });
        }
    }

    send() {
        if (this.profileForm.valid) {
            this.sendFormular.emit({
                firstname: this.profileForm.value.firstname,
                lastname: this.profileForm.value.lastname,
                tel: this.profileForm.value.tel,
                email: this.profileForm.value.email,
                message: this.profileForm.value.message.slice(0, 10000),
            });
        } else {
            this.profileForm.markAllAsTouched();
        }
    }

    close() {
        this.closeFormular.emit(true);
    }
}
