import { HttpInterceptorFn } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from '../environments/environment';

export const HttpUrlInterceptor: HttpInterceptorFn = (req: any, next) => {
    let url = 'http://127.0.0.1:3332/';
    if (req.method === 'POST') {
        if (!environment.production) {
            url = url + req.url;
        } else {
            url = req.url;
        }
        // url = url + req.url;
        // url = req.url;
        const msg = encryptUsingAES256(req.body.body);
        return next(req.clone({ url: url, body: { body: msg } }));
    } else {
        return next(req);
    }
};

function encryptUsingAES256(datatocrypt): string {
    const pass = makeid(16);
    const _key = CryptoJS.enc.Utf8.parse(pass);
    const _iv = CryptoJS.enc.Utf8.parse(pass);
    const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(datatocrypt), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }) + pass;
    return encrypted.toString();
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
